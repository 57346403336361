import moment from 'moment-timezone'
import frLocale from 'moment/src/locale/fr'

export default defineNuxtPlugin(() => {
  if (process.server) {
    moment.defineLocale('fr', frLocale._config)
    moment.tz.setDefault('Europe/Paris')
  } else if (process.client) {
    moment.defineLocale('fr', frLocale._config)
    moment.tz.setDefault('Europe/Paris')
  }
})
