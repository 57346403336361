import revive_payload_client_4sVQNw7RlN from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_9gMxCtyFUG from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/homeloop/dev/haussmann-v2-ts/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_eskviOYyLt from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import i18n_Fspu1Dj02B from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-site-config/dist/runtime/plugins/i18n.mjs";
import plugin_tbFNToZNim from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/builds/homeloop/dev/haussmann-v2-ts/.nuxt/gsapPlugin.mjs";
import i18n_yfWm7jX06p from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_XBHXiKvX2V from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-feathers-pinia/dist/runtime/plugin.mjs";
import plugin_client_W86eSGFqyZ from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/homeloop/dev/haussmann-v2-ts/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import feathers_R6WvRgOcID from "/builds/homeloop/dev/haussmann-v2-ts/plugins/feathers.ts";
import gmap_pjGla1KF16 from "/builds/homeloop/dev/haussmann-v2-ts/plugins/gmap.ts";
import head_xR53WE0jXG from "/builds/homeloop/dev/haussmann-v2-ts/plugins/head.ts";
import moment_NWWiRYbNqj from "/builds/homeloop/dev/haussmann-v2-ts/plugins/moment.ts";
import plyr_client_AWuhaKXXrg from "/builds/homeloop/dev/haussmann-v2-ts/plugins/plyr.client.ts";
import scroll_lock_xPcbcpndcQ from "/builds/homeloop/dev/haussmann-v2-ts/plugins/scroll-lock.ts";
import v_calendar_dxaVzst4iS from "/builds/homeloop/dev/haussmann-v2-ts/plugins/v-calendar.ts";
import vuetify_7h9QAQEssH from "/builds/homeloop/dev/haussmann-v2-ts/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_9gMxCtyFUG,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_eskviOYyLt,
  i18n_Fspu1Dj02B,
  plugin_tbFNToZNim,
  gsapPlugin_HlwIIyl85L,
  i18n_yfWm7jX06p,
  plugin_XBHXiKvX2V,
  plugin_client_W86eSGFqyZ,
  chunk_reload_client_UciE0i6zes,
  feathers_R6WvRgOcID,
  gmap_pjGla1KF16,
  head_xR53WE0jXG,
  moment_NWWiRYbNqj,
  plyr_client_AWuhaKXXrg,
  scroll_lock_xPcbcpndcQ,
  v_calendar_dxaVzst4iS,
  vuetify_7h9QAQEssH
]